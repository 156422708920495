import { Col, Flex, Row } from 'antd';
import { useIntl } from 'react-intl';
import BlockRT from '../util/BlockRT';
import BlockLB from '../util/BlockLB';
import LogoLT from '../util/LogoLT';
import BlockBR from '../util/BlockBR';

const Launch = () => {
    const intl = useIntl();
    return (
        <>
            <div className='page_container'>
                <Flex vertical gap={5} className='mobile page_content'>
                    <p className='page_title'>{intl.formatMessage({ id: 'launchTitle' })}</p>
                    <div className='page_img_container'>
                        <div className='page_img'>
                            <img alt='founders' src='/img/launch.png' style={{ objectFit: 'cover', width: '100%', height: '50vw' }} />
                            <LogoLT width={window.innerWidth >= 800 ? 25 : 12} />
                            <BlockRT width={window.innerWidth >= 800 ? 25 : 12} />
                            <BlockLB width={window.innerWidth >= 800 ? 25 : 12} />
                        </div>
                    </div>
                    <p className='page_info' >{intl.formatMessage({ id: 'launch_1' })}</p>
                    <p className='page_info'>{intl.formatMessage({ id: 'launch_2' })}</p>

                </Flex>


                <Flex vertical gap={5} className='pc page_content' style={{ paddingTop: '5vw' }}>
                    <Row gutter={40}>
                        <Col span={10}>
                            <div className='page_img_container'>
                                <div className='page_img'>
                                    <img alt='founders' src='/img/launch.png' style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                                    <LogoLT width={window.innerWidth >= 800 ? 25 : 12} />
                                    <BlockRT width={window.innerWidth >= 800 ? 25 : 12} />
                                    <BlockBR width={window.innerWidth >= 800 ? 25 : 12} />
                                </div>
                            </div>
                        </Col>
                        <Col span={14}>
                            <p className='page_title'>{intl.formatMessage({ id: 'launchTitle' })}</p>
                            <p className='page_info' >{intl.formatMessage({ id: 'launch_1' })}</p>
                            <p className='page_info'>{intl.formatMessage({ id: 'launch_2' })}</p>
                        </Col>
                    </Row>
                </Flex>
            </div>
        </>
    )
}
export default Launch;