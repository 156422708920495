const TechHouse3 = (props) => {
    const { margin, top } = props;
    return (
        <div style={{ margin: margin, position: 'relative', top: top }}>
            <svg width="351" height="297" viewBox="0 0 351 297" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M300.302 166.298V163.64L325.259 178.04V180.697L300.302 166.298Z" fill="#B3B3B3" />
                <path d="M325.14 239.297L350.089 224.889V221.456L325.14 235.863V239.297Z" fill="#8C8C8C" />
                <path d="M75.5561 210.618L50.5986 225.026L25.649 210.618V207.113H75.5561V210.618Z" fill="#CCCCCC" />
                <path d="M50.6307 253.778L25.6732 268.185L0.723694 253.778V250.28H50.6307V253.778Z" fill="#B3B3B3" />
                <path d="M1.12357 250.279L25.6729 236.104L50.2302 250.279L25.6729 264.455L1.12357 250.279Z" fill="#F6F6F6" />
                <path d="M25.6731 236.321L49.8302 250.272L25.6731 264.224L1.50804 250.28L25.6731 236.321ZM25.6731 235.864L0.707611 250.28L25.6571 264.688L50.6147 250.28L25.6571 235.872L25.6731 235.864Z" fill="#C9C9C9" />
                <path d="M50.3585 250.28H0.987701" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M300.19 279.04L325.139 264.632L300.182 250.224L275.232 264.632L300.19 279.04Z" fill="#F5F5F5" />
                <path d="M300.182 282.474L325.139 268.066V264.632L300.182 279.04V282.474Z" fill="#B3B3B3" />
                <path d="M325.14 268.137L350.089 253.729V250.295L325.14 264.703V268.137Z" fill="#B3B3B3" />
                <path d="M250.243 279.095V282.601L225.286 268.193V264.687" fill="#CCCCCC" />
                <path d="M225.317 268.185L200.36 282.593L175.402 268.185V264.687H225.317V268.185Z" fill="#828282" />
                <path d="M200.36 279.095L225.317 264.688L200.36 250.28L175.402 264.688L200.36 279.095Z" fill="#EBEBEB" />
                <path d="M250.243 279.095L275.201 264.688L250.243 250.28L225.286 264.688L250.243 279.095Z" fill="#F5F5F5" />
                <path d="M75.588 207.184L100.545 192.776L75.588 178.368L50.6304 192.776L75.588 207.184Z" fill="#EBEBEB" />
                <path d="M100.514 221.593L125.471 207.185L100.514 192.777L75.5564 207.185L100.514 221.593Z" fill="#F5F5F5" />
                <path d="M100.57 224.962L75.6201 239.37L50.6626 224.962V221.465H100.57V224.962Z" fill="#CCCCCC" />
                <path d="M75.5564 235.928L100.514 221.52L75.5564 207.113L50.5989 221.52L75.5564 235.928Z" fill="#EBEBEB" />
                <path d="M300.182 282.545L275.232 296.953L250.275 282.545V279.039H300.182V282.545Z" fill="#CCCCCC" />
                <path d="M275.168 293.503L300.126 279.095L275.168 264.687L250.211 279.095L275.168 293.503Z" fill="#EBEBEB" />
                <path d="M125.495 239.37L100.545 253.778L75.588 239.37V235.872H125.495V239.37Z" fill="#B3B3B3" />
                <path d="M100.545 250.28L125.495 235.872L100.545 221.465L75.588 235.872L100.545 250.28Z" fill="#F5F5F5" />
                <path d="M150.453 253.778L125.503 268.185L100.546 253.778V250.28H150.453V253.778Z" fill="#B3B3B3" />
                <path d="M125.503 264.688L150.453 250.28L125.495 235.872L100.546 250.28L125.503 264.688Z" fill="#EBEBEB" />
                <path d="M150.189 250.28H100.81" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M175.435 268.13L150.485 282.538L125.527 268.13V264.624H175.443L175.435 268.13Z" fill="#CCCCCC" />
                <path d="M150.429 282.593C150.429 282.593 175.411 297.001 200.36 282.593V279.087H150.429V282.593Z" fill="#B3B3B3" />
                <path d="M175.379 264.679L150.429 279.087C150.429 279.087 175.411 293.495 200.36 279.087L175.379 264.679Z" fill="#F5F5F5" />
                <path d="M250.131 250.224L275.089 235.816L250.131 221.408L225.174 235.816L250.131 250.224Z" fill="#F5F5F5" stroke="#C9C9C9" strokeWidth="0.400217" strokeMiterlimit="10" />
                <path d="M150.429 279.095L175.379 264.688L150.421 250.28L125.471 264.688L150.429 279.095Z" fill="#EBEBEB" stroke="#C9C9C9" strokeWidth="0.400217" strokeMiterlimit="10" />
                <path d="M26.0734 207.184L50.6307 193.009L75.188 207.184L50.6307 221.36L26.0734 207.184Z" fill="#F5F5F5" />
                <path d="M50.6304 193.233L74.7875 207.185L50.6304 221.112L26.4733 207.185L50.6304 193.233ZM50.6304 192.777L25.6729 207.185L50.6304 221.593L75.588 207.185L50.6304 192.777Z" fill="#C9C9C9" />
                <path d="M75.588 178.369L175.402 120.609L325.139 207.048V235.888L275.168 264.687L162.931 199.86L137.974 214.268L75.588 178.369Z" fill="#F0F0F0" />
                <path d="M300.302 163.64L325.251 149.233L325.259 178.04L300.302 163.64Z" fill="#F6F6F6" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M325.14 235.864L350.089 221.456L325.14 207.048L300.182 221.456L325.14 235.864Z" fill="#E0E0E0" />
                <path d="M275.232 236.008L300.182 221.6L275.232 207.192L250.275 221.6L275.232 236.008Z" fill="#F6F6F6" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M275.232 206.904L300.182 192.497L275.232 178.089L250.275 192.497L275.232 206.904Z" fill="#E0E0E0" />
                <path d="M225.293 264.688L250.243 250.28L225.285 235.872L200.336 250.28L225.293 264.688Z" fill="#F6F6F6" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M349.945 221.456H300.574" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M275.168 264.688L300.126 250.28L275.168 235.872L250.211 250.28L275.168 264.688Z" fill="#E0E0E0" />
                <path d="M325.139 264.703L350.089 250.296L325.139 235.888L300.182 250.296L325.139 264.703Z" fill="#F6F6F6" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M250.003 250.28H200.632" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M349.945 250.28H300.574" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M299.918 279.039H250.547" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M125.199 178.977H75.8282" stroke="#C9C9C9" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
                    <path d="M50.6787 221.247L75.5962 221.311L100.562 206.967L63.1415 185.356" fill="#B3B3B3" />
                </g>
                <g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
                    <path d="M75.644 235.927L100.537 235.631L113.016 228.675L88.1868 214.195L75.644 235.927Z" fill="#B3B3B3" />
                </g>
                <g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
                    <path d="M175.29 264.895L275.104 264.751L349.857 221.512L275.136 178.144L175.778 178.2" fill="#B3B3B3" />
                </g>
                <path d="M150.461 135.041L125.479 149.417L175.378 178.137L225.269 178.089L250.243 163.745L225.494 149.321V120.633L175.378 91.6978L125.399 120.553L150.461 135.041Z" fill="#E2E2E2" />
                <path d="M125.471 178.136L100.538 163.68L100.522 221.36L175.378 264.575V178.136L125.479 149.417L125.471 178.136Z" fill="#E2E2E2" />
                <path d="M150.437 192.496L175.37 206.952" stroke="white" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M150.428 221.456L162.931 228.611" stroke="white" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M175.378 178.409V264.84L250.243 221.624V164.009L225.269 178.353L225.493 149.593L175.378 178.409Z" fill="white" />
                <path d="M150.428 192.544L125.471 178.136V207.08L150.428 192.544Z" fill="#808080" />
                <path d="M200.424 192.497L225.269 178.089V206.944L200.424 221.304V192.497Z" fill="#808080" />
                <path d="M200.424 192.497L225.269 178.089V206.944L200.424 192.497Z" fill="#555555" />
                <path d="M200.328 250.167L200.32 163.744" stroke="#808080" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M150.429 250.167L150.421 221.463" stroke="white" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M225.309 235.751L225.301 206.951" stroke="#808080" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M174.657 41.6471L171.528 39.8782" stroke="white" strokeWidth="0.800434" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M150.429 163.849L125.479 149.417V178.184V178.36L150.469 192.672L150.429 163.849Z" fill="#4FC3CD" />
                <path d="M150.469 134.913L150.461 134.921L125.479 149.304L150.429 163.736L175.378 149.304L150.469 134.913Z" fill="#81D4DB" />
                <path d="M150.461 135.041L175.378 120.625V149.416L150.461 135.041Z" fill="#555555" />
                <path d="M200.344 135.041L175.426 120.625V149.416L200.344 135.041Z" fill="white" />
                <path d="M100.61 163.801L125.479 149.417V178.36L100.61 163.801Z" fill="#E2E2E2" />
                <path d="M150.46 135.041L125.479 149.416L125.471 120.625L150.46 135.041Z" fill="#E2E2E2" />
                <path d="M25.8811 207.152L25.8731 178.296L50.6386 192.6V221.464L25.8811 207.152Z" fill="#4FC3CD" />
                <path d="M50.6388 192.6L75.6284 178.176V207.032L50.6388 221.464V192.6Z" fill="#07869B" />
                <path d="M50.8707 163.881L25.8731 178.297L50.6386 192.728L75.6281 178.177L50.8707 163.881Z" fill="#81D4DB" />
                <path d="M100.522 192.648V221.488L125.471 207.08L100.522 192.648Z" fill="#D6B75B" />
                <path d="M125.511 235.904L125.471 207.08L100.522 221.488V221.592L125.511 235.904Z" fill="#D6B75B" />
                <path d="M125.471 207.08L150.428 221.456L150.436 192.496L125.471 207.08Z" fill="white" />
                <path d="M182.726 208.113L184.271 207.217V212.203L182.726 211.331V208.113Z" fill="#AAAAAA" />
                <path d="M179.965 209.706L182.726 208.113V211.323L179.965 212.924V209.706Z" fill="white" />
                <path d="M184.271 212.203L179.965 214.692V212.923L182.726 211.322V211.33L184.271 212.203Z" fill="#CEE8E4" />
                <path d="M184.271 212.203L179.965 214.692V212.923L182.726 211.322V211.33L184.271 212.203Z" fill="#AAAAAA" />
                <path d="M238.828 209.401L241.454 207.88V216.356L238.828 214.884V209.401Z" fill="#555555" />
                <path d="M234.13 212.122L238.828 209.401V214.86L234.13 217.589V212.122Z" fill="#808080" />
                <path d="M241.454 216.357V216.365L234.13 220.599V217.589L238.828 214.86V214.884L241.454 216.357Z" fill="#CEE8E4" />
                <path d="M241.454 216.357V216.365L234.13 220.599V217.589L238.828 214.86V214.884L241.454 216.357Z" fill="#555555" />
                <path d="M111.503 180.498L108.878 178.977V187.454L111.503 185.973V180.498Z" fill="white" />
                <path d="M116.202 183.212L111.504 180.498V185.957L116.202 188.678V183.212Z" fill="white" />
                <path d="M108.878 187.454V187.462L116.202 191.688V188.679L111.503 185.957V185.973L108.878 187.454Z" fill="#CEE8E4" />
                <path d="M108.878 187.454V187.462L116.202 191.688V188.679L111.503 185.957V185.973L108.878 187.454Z" fill="white" />
                <path d="M136.909 223.777L134.284 222.257V230.725L136.909 229.252V223.777Z" fill="white" />
                <path d="M141.608 226.491L136.909 223.777V229.228L141.608 231.958V226.491Z" fill="#AAAAAA" />
                <path d="M134.284 230.725V230.733L141.608 234.968V231.958L136.909 229.229V229.253L134.284 230.725Z" fill="#CEE8E4" />
                <path d="M134.284 230.725V230.733L141.608 234.968V231.958L136.909 229.229V229.253L134.284 230.725Z" fill="white" />
                <path d="M194.805 215.012L196.358 214.115V219.102L194.805 218.237V215.012Z" fill="#555555" />
                <path d="M192.043 216.614L194.805 215.013V218.223L192.043 219.832V216.614Z" fill="#808080" />
                <path d="M196.357 219.103V219.111L192.043 221.6V219.831L194.805 218.222V218.238L196.357 219.103Z" fill="#CEE8E4" />
                <path d="M196.357 219.103V219.111L192.043 221.6V219.831L194.805 218.222L196.357 219.103Z" fill="#555555" />
                <path d="M162.932 199.86V214.156L150.437 206.928" stroke="white" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M200.424 206.896L187.889 214.156V199.86" stroke="#808080" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M156.024 200.621L154.479 199.725V204.711L156.024 203.839V200.621Z" fill="white" />
                <path d="M158.785 202.214L156.024 200.621V203.831L158.785 205.431V202.214Z" fill="white" />
                <path d="M154.479 204.711L158.785 207.2V205.431L156.024 203.83V203.838L154.479 204.711Z" fill="#CEE8E4" />
                <path d="M154.479 204.711L158.785 207.2V205.431L156.024 203.83V203.838L154.479 204.711Z" fill="white" />
                <path d="M156.024 215.165L154.479 214.269V219.255L156.024 218.391V215.165Z" fill="white" />
                <path d="M158.785 216.766L156.024 215.165V218.375L158.785 219.976V216.766Z" fill="#AAAAAA" />
                <path d="M154.479 219.255V219.263L158.785 221.752V219.975L156.024 218.374V218.39L154.479 219.255Z" fill="#CEE8E4" />
                <path d="M154.479 219.255V219.263L158.785 221.752V219.975L156.024 218.374V218.39L154.479 219.255Z" fill="white" />
                <path d="M175.426 235.873L162.899 228.693L162.931 214.157L175.378 221.305L175.426 235.873Z" fill="#81D4DB" />
                <path d="M175.378 235.84L187.905 228.613V214.157L175.37 221.305L175.378 235.84Z" fill="#04AAB8" />
                <path d="M168.559 222.352L167.006 221.456V226.442L168.559 225.578V222.352Z" fill="#04AAB8" />
                <path d="M171.32 223.953L168.558 222.352V225.562L171.32 227.17V223.953Z" fill="#43BFCA" />
                <path d="M167.006 226.442V226.451L171.32 228.94V227.171L168.559 225.562V225.578L167.006 226.442Z" fill="#CEE8E4" />
                <path d="M167.006 226.442V226.451L171.32 228.94V227.171L168.559 225.562V225.578L167.006 226.442Z" fill="#04AAB8" />
                <path d="M182.582 222.352L184.127 221.456V226.442L182.582 225.578V222.352Z" fill="#43BFCA" />
                <path d="M179.82 223.953L182.582 222.352V225.562L179.82 227.17V223.953Z" fill="white" />
                <path d="M184.127 226.442V226.451L179.82 228.94V227.171L182.582 225.562V225.578L184.127 226.442Z" fill="#CEE8E4" />
                <path d="M184.127 226.442V226.451L179.82 228.94V227.171L182.582 225.562V225.578L184.127 226.442Z" fill="#43BFCA" />
                <path d="M150.437 192.697V163.841L175.194 178.145V207L150.437 192.697Z" fill="#D6B75B" />
                <path d="M175.194 178.144L200.184 163.712V192.568L175.194 207V178.144Z" fill="#7D6633" />
                <path d="M175.426 149.417L150.437 163.841L175.194 178.264L200.184 163.713L175.426 149.417Z" fill="#F4DFA7" />
                <path d="M175.482 91.5215L150.493 105.937L175.258 120.369L200.248 105.817L175.482 91.5215Z" fill="#F4DFA7" />
                <path d="M225.293 178.128L250.291 163.705V192.56L225.301 206.992L225.293 178.128Z" fill="white" />
                <path d="M100.61 163.801L125.471 178.137" stroke="white" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M125.591 120.609L150.525 135.073" stroke="white" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M89.0113 122.186L89.0033 93.3306L113.769 107.642V136.498L89.0113 122.186Z" fill="#D6B75B" />
                <path d="M113.769 107.643L138.758 93.2112V122.067L113.769 136.499V107.643Z" fill="#7D6633" />
                <path d="M113.993 78.9141L89.0033 93.3299L113.769 107.762L138.758 93.2098L113.993 78.9141Z" fill="#F4DFA7" />
                <path d="M105.74 92.6825C105.74 90.1131 109.342 88.0239 113.785 88.0239C118.227 88.0239 121.789 90.1131 121.789 92.6825C121.789 95.2518 118.187 97.341 113.785 97.341C109.382 97.341 105.74 95.2518 105.74 92.6825Z" stroke="#D6B75B" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M63.1411 228.796L50.6143 221.616L50.6464 207.088L63.0931 214.236L63.1411 228.796Z" fill="#D6B75B" />
                <path d="M63.1253 199.9L75.6121 207.088L63.0853 214.227L50.6465 207.088L63.1253 199.9Z" fill="#F4DFA7" />
                <path d="M75.6441 235.927L63.1173 228.748L63.1413 214.22L75.596 221.368L75.6441 235.927Z" fill="#E2E2E2" />
                <path d="M75.596 235.895L88.1148 228.668V214.22L75.588 221.36L75.596 235.895Z" fill="white" />
                <path d="M75.6278 207.032L88.1146 214.22L75.5878 221.36L63.1411 214.22L75.6278 207.032Z" fill="#E2E2E2" />
                <path d="M63.1256 214.269L75.6284 221.424" stroke="white" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M110.487 92.6823C110.487 91.6258 111.96 90.7693 113.785 90.7693C115.61 90.7693 117.091 91.6258 117.091 92.6823C117.091 93.7389 115.61 94.5954 113.785 94.5954C111.96 94.5954 110.487 93.7389 110.487 92.6823Z" stroke="#D6B75B" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M113.785 92.4661V40.1577" stroke="#515151" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M84.9532 2.84985V5.04304L107.069 22.4925L107.013 18.8265L112.944 22.8527V16.7934L84.9532 2.84985Z" fill="#1DB2BF" />
                <path d="M136.469 21.3239L120.965 12.199C120.965 12.199 105.116 21.516 89.4276 30.4088L105.116 39.4137C113.521 34.467 126.376 26.959 136.469 21.3239Z" fill="#81D4DB" />
                <path d="M90.6604 58.9757L74.6518 50.227V50.1389C74.1424 52.1021 73.8498 54.1152 73.7793 56.1422V57.5589L89.5558 66.5238C89.6172 63.947 89.9962 61.3876 90.6845 58.9037L90.6604 58.9757Z" fill="#81D4DB" />
                <path d="M90.66 58.9761L90.716 58.904C91.7116 54.869 93.4976 51.0715 95.9707 47.7315C98.4438 44.3914 101.555 41.575 105.124 39.4455L89.4354 30.3926C85.7374 32.4832 82.5125 35.317 79.9639 38.7156C77.4153 42.1141 75.5983 46.0037 74.6273 50.1393V50.2273L90.66 58.9761Z" fill="#B4E5EA" />
                <path d="M136.469 21.324C126.335 26.927 113.521 34.4671 105.116 39.4458C105.116 39.4458 105.252 50.4598 90.7082 58.9043C90.0204 61.3884 89.6414 63.9477 89.5796 66.5245L120.796 48.5067C136.477 39.4458 136.477 21.332 136.477 21.332L136.469 21.324Z" fill="#065467" />
                <path d="M105.116 39.4131C104.58 39.7333 104.076 40.0614 103.572 40.3896L103.099 40.7338C102.771 40.9579 102.443 41.1901 102.139 41.4222C101.955 41.5583 101.787 41.6943 101.61 41.8384C101.434 41.9825 101.058 42.2786 100.81 42.5028L100.298 42.943L99.5614 43.6314C99.4013 43.7754 99.2412 43.9275 99.0971 44.0796C98.953 44.2317 98.6169 44.5679 98.3847 44.808L97.9845 45.2322C97.7284 45.5204 97.4802 45.8086 97.2321 46.1047C97.1521 46.2088 97.056 46.3048 96.968 46.4089C96.6398 46.8091 96.3276 47.2093 96.0314 47.6255L95.9354 47.7536C95.6713 48.1218 95.4231 48.482 95.183 48.8422L94.9509 49.2024C94.7668 49.4906 94.5747 49.7867 94.4066 50.0829L94.1744 50.4751C94.0064 50.7632 93.8463 51.0434 93.6942 51.3315C93.6221 51.4596 93.5581 51.5877 93.4941 51.7157C93.334 52.0119 93.1899 52.3081 93.0458 52.6042C92.9898 52.7163 92.9418 52.8204 92.8857 52.9244C92.7257 53.2686 92.5736 53.6048 92.4295 53.949L92.3575 54.1171C91.7093 55.661 91.1743 57.2501 90.7566 58.8716C105.252 50.4591 105.116 39.4131 105.116 39.4131Z" fill="#81D4DB" />
                <path d="M89.0196 30.6885C88.4913 31.0006 87.979 31.3288 87.4827 31.665L87.0025 32.0012C86.6743 32.2333 86.3541 32.4574 86.0419 32.6976L85.5136 33.1058C85.2415 33.3299 84.9694 33.546 84.7132 33.7782L84.2009 34.2184C83.9448 34.4425 83.6967 34.6746 83.4565 34.8988L82.9923 35.355C82.7442 35.5951 82.512 35.8353 82.2799 36.0834C82.0478 36.3315 82.0078 36.3636 81.8797 36.5076C81.6235 36.7958 81.3754 37.0839 81.1353 37.3721C81.0472 37.4762 80.9512 37.5802 80.8711 37.6843C80.543 38.0845 80.2308 38.4847 79.9266 38.8929L79.8386 39.029C79.5744 39.3892 79.3183 39.7574 79.0782 40.1176L78.854 40.4698C78.6619 40.7659 78.4778 41.0621 78.3017 41.3503L78.0696 41.7505C77.9095 42.0306 77.7414 42.3188 77.5894 42.6069L77.3893 42.9831C77.2372 43.2873 77.0851 43.5835 76.941 43.8796C76.893 43.9837 76.837 44.0877 76.7889 44.1998L76.3247 45.2164L76.2526 45.3924C75.6094 46.9355 75.0744 48.5216 74.6518 50.139C89.1556 41.6944 89.0196 30.6885 89.0196 30.6885Z" fill="#9BDDE3" />
                <mask id="mask0_20_10139" style={{ mixBlendMode: 'multiply' }} maskUnits="userSpaceOnUse" x="89" y="21" width="48" height="46">
                    <path d="M136.469 21.324C126.335 26.927 113.52 34.4671 105.116 39.4458C105.116 39.4458 105.252 50.4598 90.7081 58.9043C90.0204 61.3884 89.6413 63.9477 89.5795 66.5245L120.796 48.5067C136.477 39.4458 136.477 21.332 136.477 21.332L136.469 21.324Z" fill="white" />
                </mask>
                <g mask="url(#mask0_20_10139)">
                    <path d="M147.051 24.8782C136.917 30.4812 124.102 38.0213 115.698 43C115.17 43.3202 114.657 43.6483 114.161 43.9765C113.993 44.0886 113.841 44.2087 113.681 44.3207C113.353 44.5448 113.032 44.777 112.72 45.0091C112.536 45.1452 112.368 45.2892 112.192 45.4253C112.016 45.5614 111.64 45.8656 111.391 46.0897L110.887 46.5299C110.631 46.762 110.383 46.9862 110.143 47.2183C109.903 47.4504 109.831 47.5144 109.679 47.6665C109.526 47.8186 109.198 48.1548 108.966 48.3949L108.566 48.8272C108.31 49.1046 108.062 49.3928 107.822 49.6916C107.733 49.7957 107.637 49.8917 107.557 50.0038C107.229 50.404 106.909 50.8042 106.613 51.2125C106.583 51.257 106.551 51.2998 106.517 51.3405C106.261 51.7087 106.005 52.0689 105.764 52.4291C105.684 52.5492 105.612 52.6692 105.54 52.7893C105.348 53.0775 105.164 53.3736 104.988 53.6698L104.756 54.062C104.588 54.3502 104.428 54.6303 104.276 54.9185L104.075 55.3027C103.923 55.5988 103.771 55.895 103.627 56.1912L103.467 56.5113C103.307 56.8555 103.155 57.1997 103.011 57.5359L102.939 57.704C102.291 59.248 101.756 60.837 101.338 62.4585C100.169 66.8689 100.217 79.1556 100.217 79.1556C100.217 79.1556 115.738 61.1138 131.434 52.0609C147.131 43.008 147.123 24.8942 147.123 24.8942L147.051 24.8782Z" stroke="#538996" strokeWidth="0.600325" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <path d="M76.4527 54.6616L86.6983 60.4568" stroke="white" strokeWidth="0.600325" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M113.865 17.2495V23.4769L122.478 29.312V31.4972L152.534 44.056L157.561 41.2065V39.0133L113.865 17.2495Z" fill="#1DB2BF" />
                <path d="M89.988 -0.000244141L157.561 39.0129L152.534 41.8624L122.478 29.3116L107.069 20.3068L84.9532 2.8493L89.988 -0.000244141Z" fill="#B4E5EA" />
                <path d="M68.6963 222.217L67.1435 221.32V226.307L68.6963 225.435V222.217Z" fill="white" />
                <path d="M71.458 223.81L68.6965 222.217V225.427L71.458 227.027V223.81Z" fill="#AAAAAA" />
                <path d="M67.1435 226.306L71.4578 228.796V227.027L68.6963 225.426V225.434L67.1435 226.306Z" fill="#CEE8E4" />
                <path d="M67.1435 226.306L71.4578 228.796V227.027L68.6963 225.426V225.434L67.1435 226.306Z" fill="white" />
                <path d="M225.493 120.634L200.343 135.042L225.493 149.322V120.634Z" fill="white" />
                <path d="M250.243 192.552L187.937 228.611" stroke="#808080" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M273.608 206.985C273.608 221.28 273.608 221.392 273.608 221.392L286.062 222.257L286.126 199.765L273.608 206.985Z" fill="#81D4DB" />
                <path d="M228.639 156.628L257.182 168.963V170.556L253.524 172.629L228.639 158.221V156.628Z" fill="#2B2B2B" />
                <path d="M232.105 155.532L257.182 169.876L253.524 171.949L228.639 157.541L232.105 155.532Z" fill="#595959" />
                <path d="M241.086 149.377L269.637 161.711V163.304L265.971 165.377L241.086 150.97V149.377Z" fill="#2B2B2B" />
                <path d="M244.56 148.28L269.637 162.624L265.971 164.697L241.086 150.289L244.56 148.28Z" fill="#595959" />
                <path d="M222.163 159.414V130.559L246.921 144.862V173.734L222.163 159.414Z" fill="#D6B75B" />
                <path d="M246.921 144.863L271.91 130.439V159.295L246.921 173.735V144.863Z" fill="#7D6633" />
                <path d="M247.153 116.143L222.163 130.559L246.921 144.991L271.91 130.439L247.153 116.143Z" fill="#F4DFA7" />
                <path d="M267.845 215.196L265.98 142.989L267.837 141.965L269.694 142.989V214.124L267.845 215.196Z" fill="#2B2B2B" />
                <path d="M255.357 227.771L253.5 150.121L255.349 149.096L257.214 150.121V226.706L255.357 227.771Z" fill="#2B2B2B" />
                <path d="M255.357 227.771V151.194L253.5 150.121V226.707L255.357 227.771Z" fill="#595959" />
                <path d="M267.837 215.197V144.054L265.98 142.99V214.124L267.837 215.197Z" fill="#595959" />
                <path d="M248.706 221.335L261.145 214.131C260.32 213.723 248.762 207.68 248.706 221.335Z" fill="#8ED9DF" />
                <path d="M250.13 235.416C250.13 235.667 250.056 235.913 249.916 236.123C249.776 236.332 249.577 236.495 249.345 236.591C249.112 236.688 248.856 236.713 248.61 236.664C248.363 236.615 248.136 236.493 247.958 236.315C247.78 236.137 247.659 235.911 247.61 235.664C247.56 235.417 247.586 235.161 247.682 234.928C247.778 234.696 247.941 234.497 248.151 234.357C248.36 234.217 248.606 234.143 248.858 234.143C249.195 234.143 249.519 234.277 249.758 234.516C249.996 234.754 250.13 235.078 250.13 235.416Z" fill="#E2E2E2" />
                <path d="M248.682 237.977V229.468L273.639 243.876V252.385L248.682 237.977Z" fill="#4FC3CD" />
                <path d="M298.533 237.977V229.468L273.583 243.876V252.385L298.533 237.977Z" fill="#065467" />
                <path d="M277.322 246.43L295.595 235.88" stroke="white" strokeWidth="0.600325" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M256.622 241.587C256.622 242.916 255.869 243.988 254.941 243.988C254.012 243.988 253.268 242.908 253.268 241.587C253.268 240.266 254.02 239.186 254.941 239.186C255.861 239.186 256.622 240.25 256.622 241.587Z" fill="black" />
                <path d="M268.701 248.623C268.701 249.952 267.948 251.025 267.02 251.025C266.091 251.025 265.339 249.944 265.339 248.623C265.339 247.303 266.091 246.222 267.02 246.222C267.948 246.222 268.701 247.295 268.701 248.623Z" fill="black" />
                <path d="M273.608 206.984L286.102 199.78C286.102 199.78 273.656 192.744 273.608 206.984Z" fill="#9BDDE3" />
                <path d="M251.94 213.171H251.9V213.243C255.398 211.178 260.584 213.891 261.129 214.179L273.592 206.976C273.592 202.229 275 199.852 276.849 198.787H276.809L251.94 213.171Z" fill="#CDEEF1" />
                <path d="M298.533 229.468L286.038 222.28L261.136 236.688L273.583 243.876L298.533 229.468Z" fill="#81D4DB" />
                <path d="M261.192 214.195L286.102 199.788L286.038 222.28L261.136 236.688L261.192 214.195Z" fill="#065467" />
                <path d="M261.193 214.197H261.145L248.706 221.4C248.706 221.4 248.706 221.464 248.706 221.497C248.706 234.303 248.706 229.349 248.706 229.501L261.193 222.297V214.197Z" fill="#8ED9DF" />
                <path d="M261.137 236.688V222.232L248.65 229.436L261.137 236.688Z" fill="#04AAB8" />
                <path opacity="0.15" d="M267.837 203.99V210.337L269.694 209.257V202.909L267.837 203.99Z" fill="#20B3C0" />
                <path opacity="0.3" d="M255.357 227.756V227.892L257.214 226.827V210.154L255.357 211.235V227.756Z" fill="#20B3C0" />
                <g opacity="0.3">
                    <path d="M253.5 212.308V226.827L255.358 227.892V227.756L255.053 211.411L253.5 212.308Z" fill="#20B3C0" />
                    <path d="M255.054 211.411L255.358 227.756V211.235L255.054 211.411Z" fill="#20B3C0" />
                </g>
                <path opacity="0.15" d="M267.532 204.167L265.98 205.072V211.419L267.7 210.418L267.837 210.338V203.991L267.532 204.167Z" fill="#20B3C0" />
                <mask id="mask1_20_10139" style={{ mixBlendMode: 'multiply' }} maskUnits="userSpaceOnUse" x="222" y="130" width="25" height="44">
                    <path d="M222.163 159.414V130.559L246.921 144.862V173.734L222.163 159.414Z" fill="white" />
                </mask>
                <g mask="url(#mask1_20_10139)">
                    <g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
                        <path d="M242.455 194.73L242.27 160.111L217.313 145.703V174.519L242.455 194.73Z" fill="#B3B3B3" />
                    </g>
                </g>
                <path d="M200.312 163.833L225.309 149.401L225.301 178.265L200.32 192.689L200.312 163.833Z" fill="white" />
                <path d="M213.847 166.482L216.472 164.969V173.438L213.847 171.965V166.482Z" fill="#AAAAAA" />
                <path d="M209.149 169.204L213.847 166.482V171.941L209.149 174.671V169.204Z" fill="white" />
                <path d="M216.473 173.438V173.446L209.149 177.68V174.67L213.847 171.941V171.965L216.473 173.438Z" fill="#CEE8E4" />
                <path d="M216.473 173.438V173.446L209.149 177.68V174.67L213.847 171.941V171.965L216.473 173.438Z" fill="#AAAAAA" />
                <path d="M200.543 135.104L175.554 149.52L200.319 163.944L225.309 149.4L200.543 135.104Z" fill="#E2E2E2" />
                <g style={{ mixBlendMode: 'multiply' }}>
                    <path d="M149.108 266.816H167.542" stroke="#B3B3B3" strokeWidth="1.60087" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <path d="M150.444 259.38H149.716C149.053 259.38 148.515 259.918 148.515 260.581V265.68C148.515 266.343 149.053 266.88 149.716 266.88H150.444C151.108 266.88 151.645 266.343 151.645 265.68V260.581C151.645 259.918 151.108 259.38 150.444 259.38Z" fill="black" />
                <path d="M150.093 261.934C143.441 261.934 142.753 258.108 143.441 254.538C144.457 249.263 147.499 239.738 150.093 239.738C152.686 239.738 155.696 249.151 156.744 254.538C157.376 257.836 156.744 261.934 150.093 261.934Z" fill="#009385" />
                <mask id="mask2_20_10139" style={{ mixBlendMode: 'multiply' }} maskUnits="userSpaceOnUse" x="143" y="239" width="14" height="23">
                    <path d="M150.093 261.934C143.441 261.934 142.753 258.108 143.441 254.538C144.458 249.263 147.499 239.738 150.093 239.738C152.686 239.738 155.696 249.151 156.744 254.538C157.377 257.836 156.744 261.934 150.093 261.934Z" fill="white" />
                </mask>
                <g mask="url(#mask2_20_10139)">
                    <path d="M154.623 244.261C151.606 244.789 153.559 253.682 150.357 255.811C147.155 257.94 143.953 255.987 144.138 261.678C144.322 267.369 161.379 262.567 161.379 262.567L154.623 244.261Z" fill="#005B4C" />
                </g>
                <path d="M214.367 220.144L215.912 219.247V241.475L214.367 240.611V220.144Z" fill="#AAAAAA" />
                <path d="M211.598 221.744L214.367 220.144V240.595L211.598 242.204V221.744Z" fill="white" />
                <path d="M215.912 241.475V241.483L211.598 243.972V242.203L214.367 240.594V240.61L215.912 241.475Z" fill="#CEE8E4" />
                <path d="M215.912 241.475V241.483L211.598 243.972V242.203L214.367 240.594V240.61L215.912 241.475Z" fill="#AAAAAA" />
                <path d="M264.795 216.694L283.061 206.144V219.792L264.795 230.341V216.694Z" stroke="#538996" strokeWidth="0.600325" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M250.291 233.43L252.612 234.903" stroke="white" strokeWidth="0.600325" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M285.918 203.67V194.874" stroke="#065467" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M261.369 217.958V209.161" stroke="#065467" strokeWidth="0.400217" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M99.9054 242.107C101.011 242.107 101.906 241.211 101.906 240.106C101.906 239.001 101.011 238.105 99.9054 238.105C98.8002 238.105 97.9043 239.001 97.9043 240.106C97.9043 241.211 98.8002 242.107 99.9054 242.107Z" fill="#04AAB8" />
                <path d="M331.783 247.878C332.888 247.878 333.784 246.982 333.784 245.877C333.784 244.772 332.888 243.876 331.783 243.876C330.678 243.876 329.782 244.772 329.782 245.877C329.782 246.982 330.678 247.878 331.783 247.878Z" fill="#04AAB8" />
                <path d="M318.368 256.386C319.473 256.386 320.369 255.49 320.369 254.385C320.369 253.28 319.473 252.384 318.368 252.384C317.263 252.384 316.367 253.28 316.367 254.385C316.367 255.49 317.263 256.386 318.368 256.386Z" fill="#AAAAAA" />
                <path d="M296.532 202.485C296.532 201.955 296.743 201.446 297.118 201.07C297.493 200.695 298.002 200.484 298.533 200.484C299.064 200.484 299.573 200.695 299.948 201.07C300.323 201.446 300.534 201.955 300.534 202.485V206.255C300.534 207.136 299.229 207.504 298.533 207.504C297.837 207.504 296.532 207.136 296.532 206.255V202.485Z" fill="#065467" />
                <path d="M108.262 234.575C108.262 234.044 108.473 233.535 108.848 233.16C109.223 232.785 109.732 232.574 110.263 232.574C110.794 232.574 111.303 232.785 111.678 233.16C112.053 233.535 112.264 234.044 112.264 234.575V238.345C112.264 239.226 110.967 239.594 110.263 239.594C109.559 239.594 108.262 239.226 108.262 238.345V234.575Z" fill="#AAAAAA" />
                <path d="M320.713 242.491C320.713 241.96 320.924 241.451 321.299 241.076C321.674 240.701 322.183 240.49 322.714 240.49C323.245 240.49 323.754 240.701 324.129 241.076C324.504 241.451 324.715 241.96 324.715 242.491V246.261C324.715 247.142 323.418 247.51 322.722 247.51C322.026 247.51 320.721 247.142 320.721 246.261L320.713 242.491Z" fill="#AAAAAA" />
            </svg>


        </div>
    )
}
export default TechHouse3;