import { Col, Flex, Row } from 'antd';
import { useIntl } from 'react-intl';
import BlockRT from '../util/BlockRT';
import BlockLB from '../util/BlockLB';
import './Valuable.css';

const Valuable = () => {
    const intl = useIntl();
    return (
        <>
            <div className='page_container'>
                <Flex vertical gap={5} className='mobile page_content'>
                    <p className='page_title'>{intl.formatMessage({ id: 'valuableTitle' })}</p>
                    <div className='page_img_container'>
                        <div className='page_img'>
                            {/* <img alt='founders' src='/img/rotatecoin.png' style={{ objectFit: 'cover', width: '100%', height: '100%' }} /> */}
                            <div style={{ width: '100%', height: '50vw' }}>
                                <img alt='founders' src='/img/smallcoin.png' className='small_coin' />
                                <img alt='founders' src='/img/rotatecoin.png' className='big_coin' />
                            </div>
                            <BlockRT width={window.innerWidth >= 800 ? 25 : 12} />
                            <BlockLB width={window.innerWidth >= 800 ? 25 : 12} />
                        </div>
                    </div>
                    <p className='page_info' >{intl.formatMessage({ id: 'valuable_1' })}</p>
                    <p className='page_info'>{intl.formatMessage({ id: 'valuable_2' })}</p>

                </Flex>


                <Flex vertical gap={5} className='pc page_content' style={{ paddingTop: '5vw' }}>
                    <Row gutter={40}>
                        <Col span={14}>
                            <p className='page_title'>{intl.formatMessage({ id: 'valuableTitle' })}</p>
                            <p className='page_info' >{intl.formatMessage({ id: 'valuable_1' })}</p>
                            <p className='page_info'>{intl.formatMessage({ id: 'valuable_2' })}</p>
                        </Col>
                        <Col span={10}>
                            <div className='page_img_container'>
                                <div className='page_img'>
                                    {/* <img alt='founders' src='/img/rotatecoin.png' style={{ objectFit: 'cover', width: '100%', height: '100%' }} /> */}
                                    <div style={{ width: '100%', height: '100%' }}>
                                        <img alt='founders' src='/img/smallcoin.png' className='small_coin' />
                                        <img alt='founders' src='/img/rotatecoin.png' className='big_coin' />
                                    </div>
                                    <BlockRT width={window.innerWidth >= 800 ? 25 : 12} />
                                    <BlockLB width={window.innerWidth >= 800 ? 25 : 12} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Flex>
            </div>
        </>
    )
}
export default Valuable;