import './Progressive.css';
import { Flex } from "antd";
const Item = (props) => {
    const { imgSrc, text, title } = props;
    return (
        <div className="step_container">
            <img src={imgSrc} alt="" className="step_img" />
            <Flex vertical className="step_text_container" justify='center'>
                <span className="font_neue step_title">{title}</span>
                <span className="fotn_neue step_text">{text}</span>
            </Flex>
        </div>


    )
}
export default Item;