import { Flex } from 'antd';
import './Future.css';
import { useIntl } from 'react-intl';
import TechHouse1 from '../util/TechHouse/TechHouse1';
import TechHouse2 from '../util/TechHouse/TechHouse2';
import TechHouse3 from '../util/TechHouse/TechHouse3';

const Future = () => {
    const intl = useIntl();
    return (
        <>

            <div className='mobile' style={{
                flexDirection: 'column',
                alignItems: 'center', position: 'relative', paddingBottom: '40px',
                paddingTop: '10px', width: '100vw', backgroundColor: 'black'
            }}>

                <Flex align='center' style={{ position: 'relative', width: '95%', height: '100%', borderColor: '#F7931A', borderStyle: 'solid', borderWidth: '1px' }}>
                    <Flex vertical style={{ margin: 10 }}>
                        <p className='page_title'>{intl.formatMessage({ id: 'futureTitle' })}</p>
                        <p className='page_info'>{intl.formatMessage({ id: 'future_1' })}</p>
                    </Flex>
                    <img src='/img/tech-1.png' style={{ width: 80, height: 80 }} alt='' />
                </Flex>
                <Flex align='center' style={{ position: 'relative', width: '95%', height: '100%', borderColor: '#F7931A', borderStyle: 'solid', borderWidth: '1px' }}>
                    <img src='/img/tech-1.png' style={{ width: 80, height: 80 }} alt='' />
                    <Flex vertical style={{ margin: 30 }}>
                        <p className='page_title'>{intl.formatMessage({ id: 'backgroundTitle' })}</p>
                        <p className='page_info'>{intl.formatMessage({ id: 'background_1' })}</p>
                    </Flex>
                </Flex>
                <Flex align='center' style={{ position: 'relative', width: '95%', height: '100%', borderColor: '#F7931A', borderStyle: 'solid', borderWidth: '1px' }}>
                    <Flex vertical style={{ margin: 30 }}>
                        <p className='page_title'>{intl.formatMessage({ id: 'inscriptionTitle' })}</p>
                        <p className='page_info'>{intl.formatMessage({ id: 'inscription_1' })}</p>
                        <p className='page_info'>{intl.formatMessage({ id: 'inscription_2' })}</p>
                    </Flex>
                    <img src='/img/tech-1.png' style={{ width: 80, height: 80 }} alt='' />

                </Flex>
            </div>

            <div className='pc' style={{
                flexDirection: 'column',
                alignItems: 'center', position: 'relative', paddingBottom: '100px',
                paddingTop: '100px', width: '100vw', height: 'fit', backgroundColor: 'black'
            }}>
                <Flex style={{ position: 'relative', width: '90%', height: '100%', borderColor: '#F7931A', borderStyle: 'solid', borderWidth: '1px' }}>
                    <Flex vertical style={{ margin: 30 }}>
                        <p className='page_title'>{intl.formatMessage({ id: 'futureTitle' })}</p>
                        <p className='page_info'>{intl.formatMessage({ id: 'future_1' })}</p>
                    </Flex>
                    <TechHouse1 margin={30} top={'-20px'} />
                </Flex>
                <Flex style={{ position: 'relative', width: '90%', height: '100%', borderColor: '#F7931A', borderStyle: 'solid', borderWidth: '1px' }}>
                    <TechHouse2 margin={30} top={'-5px'} />
                    <Flex vertical style={{ margin: 30 }}>
                        <p className='page_title'>{intl.formatMessage({ id: 'backgroundTitle' })}</p>
                        <p className='page_info'>{intl.formatMessage({ id: 'background_1' })}</p>
                    </Flex>
                </Flex>
                <Flex style={{ position: 'relative', width: '90%', height: '100%', borderColor: '#F7931A', borderStyle: 'solid', borderWidth: '1px' }}>
                    <Flex vertical style={{ margin: 30 }}>
                        <p className='page_title'>{intl.formatMessage({ id: 'inscriptionTitle' })}</p>
                        <p className='page_info'>{intl.formatMessage({ id: 'inscription_1' })}</p>
                        <p className='page_info'>{intl.formatMessage({ id: 'inscription_2' })}</p>
                    </Flex>
                    <TechHouse3 margin={30} top={'-10px'} />

                </Flex>
            </div>
        </>
    )
}
export default Future;