import { Flex } from 'antd';
import './Footer.css';
const Footer = () => {
    return (
        <>
            <Flex justify='space-between' style={{
                borderTop: '#F7931A 1px solid', backgroundColor: '#000', width: '100%',
                flexDirection: window.innerWidth >= 800 ? 'row' : 'column',
            }}>
                <Flex vertical gap={5} style={{ margin: window.innerWidth >= 800 ? 30 : 5 }}>
                    <Flex align={'center'} gap={5}>
                        <img src="/img/aisilogo.png" className="nav_logo" alt="logo" />
                        <span className="font_inter nav_title">AISI DAO</span>
                    </Flex>
                    <span className='page_info footer_copyright'>Copyright © 2024 AISI DAO S.L. All rights reserved.</span>
                </Flex>
                <Flex gap={window.innerWidth >= 800 ? 50 : 40} style={{ margin: window.innerWidth >= 800 ? 30 : 10 }}>
                    <a href='https://twitter.com/AISI_2013' target='_blank' rel='noreferrer'>
                        <img src="/img/twitter1.png" className='footer_icon' alt="logo" />
                    </a>
                    <a href='https://twitter.com/aisidao_' target='_blank' rel='noreferrer'>
                        <img src="/img/twitterEN.png" className='footer_icon' alt="logo" />
                    </a>
                    <a href='https://t.me/AISI_2013/1' target='_blank' rel='noreferrer'>
                        <img src="/img/teleCN.png" className='footer_icon' alt="logo" />
                    </a>
                    <a href='https://t.me/AiSiDaos' target='_blank' rel='noreferrer'>
                        <img src="/img/teleEN.png" className='footer_icon' alt="logo" />
                    </a>
                </Flex>
            </Flex>

          
        </>
    )
}
export default Footer;