
const BlockRT = (props) => {
    const { width } = props;
    const totalWidth = width * 2;
    return (
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width={totalWidth} height={totalWidth} viewBox={"0 0 " + totalWidth + " " + totalWidth} fill="none">
                <rect x={width} width={width} height={width} fill="#F7931A" />
                <rect y={width} width={width} height={width} fill="#F7931A" />
            </svg>
        </div>
    )
}
export default BlockRT;