const zh = {
    superHello: "你好, { someone } !",

    commonSubmit: '提交',
    switchLang: '切换语言',
    hero_info: '一个有高度、有态度、有温度的社区',
    hero_info_1: '一个有高度、有态度、有温度的',
    hero_info_2: '社区 ',
    hero_info_3: ' ',
    hero_info_4: ' ',
    revolution_1: '无人生来大佬，大佬也曾屌丝。',
    revolution_2: '认知改变命运，力量属于社区。',
    historyTitle: 'AISI DAO的历史',
    history_1: '爱思社区成⽴于2013年，由5位区块链⾏业最早期的布道者在上海爱思咖啡馆聚会所创⽴，汇聚了币圈最早的⾏业从业者，是中国乃⾄全球创建的第⼀个区块链专业社群。也是全球存续时间最⻓的区块链⾏业社区。',
    history_2: '爱思令牌是基于以太坊ERC20智能合约，2018年6⽉1⽇达成社区共识所发⾏，免费铸造赠送，总量 500 枚，500位爱思群友⼀⼈⼀枚，公平公正。旨在探讨区块链结合社区共识的实践，⾸创去中⼼化的⾃治组织雏形。爱思令牌在24小时内就完成了共识、发放、交易所主动上币，成交价最⾼达到400万⼀枚，成为历史上最贵的单个数字资产。包括cobo、币宝、库神硬件钱包在内的多家区块链⾏业知名企业第⼀时间提供了服务⽀持。',
    history_3: '爱思社区聚集⾏业内外众多⼤佬，他们经常在社区发表各⾃对区块链的观点、思考和投资机会，对于发布爱思币缘由，媒体采访了很多圈内⼈⼠，⼤家普遍认为，这是⼀次“伟⼤的社会实验”。',

    launchTitle: '2018年的公平发射实践',
    launch_1: '社区⾃治理念共识的关键是⼀⼈⼀币，⼈⼈平等。今年⽕热的BRC20公平发射的思想5年前已经在爱思社区实现，⼀⼈⼀币，免费赠送，币价完全靠社区内在价值，让持有变成乐趣和主动贡献。⽆论这次社会实验是不是乌托邦类的理想主义，爱思令牌都是区块链历史上⼀次伟⼤的试验，极具历史纪念意义和收藏价值。',
    launch_2: '爱思群需要群友推荐，审核认证，应该说500个爱思令牌就是500个超级节点，卖出就是转移节点，爱思令牌的持有者⼤多已财富⾃由，或是业内领军⼈物，⾮常珍惜爱思令牌，总计只交易了3枚，价格最⾼达到了五⼗枚BTC，如果把爱思令牌转让出去，⾃然失去在群资格，而买到爱思令牌的⼈⾃然成为这个群的成员。',

    valuableTitle: '爱思令牌是最有价值的空投权益令牌',
    valuable_1: '持有爱思令牌可以享受⽣态⽀持，令牌社交圈，500⼈共识散发，获得交易所优先上币，机构优先投资，基⽯优先准⼊的机会，并接受爱思令牌社区的监督和推进，传递价值共识，形成拥有爱思令牌是巨⼤荣誉和价值的共识。而对外形成的共识是拥有爱思令牌的⼈都具有币圈影响⼒。',
    valuable_2: '爱思令牌发⾏以后，各种区块链项⽬开始给爱思令牌持有⼈的ETH地址空投代币，最⼤的⼀笔空投是2018年抹茶交易所向每个爱思令牌地址空投了50000个MX，总计500份，截⽌⽬前为⽌价值五亿⼈⺠币，平均每个地址获得了⼀百万元的空投。其他各类众多项⽬的空投在经过严格审核后，空投各类代币累计过百余次。成为当时最有价值的区块链⾏业交流社区令牌。',

    progressiveTitle: '新⼀代的渐进式权益空投模型',
    progressive_1: '抹茶交易所平台币空投和社区的权益互动给了我们⼀种全新的思考，抹茶平台币经过5年的时间，市值增⻓了10倍。2018年收到抹茶空投以后，爱思委员会发动⼏百个KOL同⼀时间在朋友圈为抹茶点赞，这⼏百个KOL覆盖了当时所有的币圈资源，抹茶交易所⼀夜引爆。社区为项⽬⽅赋能，而不是撸⽺毛，项⽬⽅通过权益的渐进式空投让社区成为项⽬⽅最忠诚的⻓期陪伴者，形成良性增⻓循坏。⼀个⻓期的陪伴可以让社区与平台的⻓期成功联系起来，而不是关注短期收益。',
    progressive_2: '渐进式空投包含4个阶段，每个阶段完成KPI获得持续空投',
    step_1_title: '空投',
    step_1_content: '优质项⽬的空投筛选',
    step_2_title: '社区扩散',
    step_2_content: '社区宣传扩散项⽬的影响⼒',
    step_3_title: '使徒行为',
    step_3_content: '愿意说服他⼈使⽤项⽬⽅的产品',
    step_4_title: '忠诚联盟',
    step_4_content: '⻓期陪伴，持续获得项⽬⽅的奖励，持续给项⽬输⼊个性化体验，互动和⽤⼾',

    futureTitle: '爱思社区未来发展',
    future_1: '随着Web3时代的到来，爱思群友也得到了很多国际社会⾏业精英的关注和认可，爱思社区也需要扩⼤到全球发展层⾯，⽤DAO的组织架构，为⾏业的发展继续做出应有的贡献。基于爱思微信群升级为AISIDAO社区，覆盖微信，电报，推特，由国内扩散到全球的区块链爱好者社区，社区⼤扩散，成⽴万⼈社区，开启新⼀代的渐进式权益空投模型社会实验。如果说APE是全球各⾏业精英的聚集地，YGG是全球最⼤的⼯会，那么AISIDAO就是要成为全球Web3投资者的去中⼼化⾃治社区俱乐部。',

    backgroundTitle: '赛道背景',
    background_1: 'BRC20的公平发射完全符合爱思社区的思想。今年BRC赛道诞⽣了⽆数财富的神话，ORDI铸造的成本2U⼀张，最⾼涨到69000U⼀张，年内涨幅34500倍。BRC20掀起了万亿⽐特币⼤⽣态，币安、OKX等头部交易所已参与BRC⽣态建设，BRC必将成为本轮⽜市的最⼤新叙事。爱思社区的创始⼈今年5⽉开始布道BRC赛道，应HuoBi、Gate交易所之邀在推特上做了数场直播，对BRC赛道有深⼊的研究。',

    inscriptionTitle: 'aisi铭文部署及mint完成',
    inscription_1: '2023年6月8日aisi铭文被不知名人士在BRC20上部署，mint了一张铭文，进度0.1%， 2023年11月16日，aisi铭文被爱思社区成员阿猛先生发现并转发到爱思社区，当时铭文进度0.1%，2023年12月21日，历经三个小时AISI铭文全部打完，在这期间，铭文获得社区和散户的广泛关注与参与，铭文初始市值约100万，前10持仓占比9.93%，初始地址约1000个，从0.1%被社区发现完成铸造，筹码分散，完全符合铭文赛道的本意和精神，得到了爱思社区的认可，aisi铭文成为新一代的爱思令牌。',
    inscription_2: 'AISI社区开放、透明、拥抱所有的社区和项目，继承AISI令牌的空投传统，让AISI铭文成为空投金铲子，第一期空投20EX已经完成对AISI的空投，后续更多空投陆续进行中',
    amount_1: '总量：42 000 000',
    amount_2: '张数：42 000',
    amount_3: '每张：1000',

    footer_link_1: 'AISI 社区推特',
    footer_link_2: 'AISI 推特(英文)',
    footer_link_3: 'AISI 电报群 (中文)',
    footer_link_4: 'AISI 电报群 (英文)',

    footer_link_5: 'AISI K线图',
    footer_link_6: '如何购买AISI',
    footer_link_7: '通过欧易购买AISI',
    footer_link_8: '通过Unisat购买AISI',
}
export default zh;  