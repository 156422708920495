import { Flex } from "antd"
import './Revolution.css';
import { useIntl } from "react-intl";
import BlockRT from "../util/BlockRT";
import BlockLB from "../util/BlockLB";
const Revolution = () => {
    const intl = useIntl();
    return (
        <div className="revolution_container" >
            <div className="revolution_black_bg"></div>
            <Flex className="revolution_content" align="center" justify="center" >
                <div className="revolution_paint">
                    {/* <span className="revolution_info">testtesttesttesttesttesttesttesttesttesttesttesttest</span> */}
                </div>
                <div className="revolution_info_container">
                    <img src="/img/aisilogo.png" alt="" className="revolution_info_logo"></img>
                    <Flex vertical align="center">
                        <span>{intl.formatMessage({ id: 'revolution_1' })}</span>
                        <span>{intl.formatMessage({ id: 'revolution_2' })}</span>
                    </Flex>

                </div>
                {/* <span className="revolution_info">testtesttesttesttesttesttesttesttesttesttesttesttest</span> */}

                <BlockRT width={window.innerWidth >= 800 ? 25 : 12} />
                <BlockLB width={window.innerWidth >= 800 ? 25 : 12} />
            </Flex>

        </div>
    )
}

export default Revolution;