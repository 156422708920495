import { Flex } from "antd"
import './Hero.css';
import { useIntl } from "react-intl";
import { UnionMobile, UnionPC } from "../util";

const Hero = () => {
    const intl = useIntl();

    return (
        <div className="page_container" >
            <Flex className="bg_container hero_bg" vertical align={'center'} gap={20} justify="center">
                <Flex className="hero_info_row" vertical align="center">
                    <Flex gap={window.innerWidth >= 800 ? 10 : 5}>
                        <span className="font_inter hero_info">{intl.formatMessage({ id: 'hero_info_1' })}</span>
                        <span className="font_inter hero_info hero_info_orange">{intl.formatMessage({ id: 'hero_info_2' })}</span>
                        <span className="font_inter hero_info">{intl.formatMessage({ id: 'hero_info_3' })}</span>
                    </Flex>
                    <Flex>
                        <span className="font_inter hero_info">{intl.formatMessage({ id: 'hero_info_4' })}</span>

                    </Flex>
                </Flex>
                {window.innerWidth >= 800 ? <UnionPC /> : <UnionMobile />}
                <div className="linear_block"></div>
            </Flex>
        </div>

    )
}

export default Hero;