const en = {
    superHello: "Hello, { someone } !",

    commonSubmit: 'Submit',
    switchLang: 'Switch Language',
    hero_info_1: 'A',
    hero_info_2: ' community ',
    hero_info_3: 'with high',
    hero_info_4: 'standards, attitude and warmth',
    revolution_1: 'No one is born a boss. Even bosses were once losers.',
    revolution_2: 'Knowledge changes destiny. Power belongs to the community.',
    historyTitle: 'History of AISI DAO',
    history_1: 'AISI Community was established in 2013 by five pioneers in the blockchain industry who gathered at the AISI Café in Shanghai. It brought together some of the earliest practitioners in the ryptocurrency field, making it the first professional blockchain community in China and globally, and also the longest-standing blockchain industry community worldwide.',
    history_2: 'The AISI Token, based on the Ethereum ERC20 smart contract, was issued on June 1, 2018, through community consensus. It was distributed through a fair and just process of free minting and gifting, with a total supply of 500 tokens. Each member of the AISI community received one token. The token aimed to explore the practical integration of blockchain with ommunity consensus and pioneered the prototype of a decentralized autonomous organization (DAO). The AISI Token achieved consensus, distribution, and active listing on exchanges within 24 hours, reaching a record-breaking highest transaction price of 4 million USD per token, making it the most expensive individual digital asset in history. Several well-known companies in the blockchain industry, including Cobo, Bibao, and KUSHEN Hardware Wallet, provided immediate service support. ',
    history_3: 'The AISI Community attracts numerous industry experts both inside and outside the field, who regularly share their insights, thoughts, and investment opportunities related to blockchain in the community. Regarding the release of the AISI Token, many industry insiders interviewed by the media generally consider it a " great social experiment."',

    launchTitle: 'Fair Launch Experiment of 2018',
    launch_1: 'The key to consensus in community self-governance is " one person, one token, " emphasizing equality for everyone. The concept of fair launch, exemplified by the recent BRC20 trend, was implemented in the AISI community five years ago. " One person, one token, " distributed for free, and the token price determined entirely by the intrinsic value within the community, turned token holding into a source of enjoyment and active contribution. Regardless of whether this social experiment embodies utopian ideals, the AISI Token represents a significant experiment in blockchain history, holding historical and collectible value.',
    launch_2: 'The AISI community relies on recommendations and verifications from its members. It can be said that each of the 500 AISI Tokens represents a super node, and selling or transferring a token corresponds to changing a node. Most AISI Token holders are financially independent or industry leaders, highly valuing their tokens. Only three transactions have occurred, with the highest price reaching fifty BTC per token. If an AISI Token is transferred, the holder naturally loses their membership status in the community, while the purchaser becomes a member of the group.',

    valuableTitle: 'The AISI Token is the most valuable airdrop equity token',
    valuable_1: 'Holding the AISI Token provides access to ecosystem support, token social circles, consensus istribution among 500 members, priority listing on exchanges, institutional priority investment, cornerstone priority access opportunities. Token holders also accept supervision and promotion from the AISI Token community, fostering a consensus on value and creating a recognition that possessing AISI Tokens is a significant honor and holds substantial value. The external consensus is that individuals with AISI Tokens have a considerable influence in the cryptocurrency community.',
    valuable_2: 'After the issuance of AISI Tokens, various blockchain projects began airdropping tokens to the ETH addresses of AISI Token holders. The largest airdrop was in 2018 when Matcha Exchange airdropped 50,000 MX to each AISI Token address, totaling 500 distributions, with a cumulative market value of five hundred million CNY. On average, each address received a one million CNY airdrop. Following strict scrutiny, numerous other projects conducted over a hundred airdrops of various tokens, establishing the AISI Token as the most valuable token in the blockchain industry communication community at that time.',

    progressiveTitle: 'The new generation progressive equity airdrop model',
    progressive_1: 'The airdrop and community engagement with the platform token of Matcha Exchange have given us a new perspective. After five years, the market value of Matcha platform token has increased tenfold. Following the Matcha airdrop in 2018, the AISI committee mobilized hundreds of Key Opinion Leaders (KOLs) to simultaneously endorse Matcha on their social media accounts. These KOLs covered all the available resources in the cryptocurrency community at that time, resulting in an overnight explosion for Matcha Exchange. Empowering the project with the community, rather than merely pursuing short-term gains, Matcha Exchange, through the progressive airdrop of equity, turned the community into the project\' s most loyal and long-term companions. This approach established a positive growth cycle. Long-term companionship allows the community to be intricately linked to the platform\' s long-term success, rather than focusing solely on short-term gains.',
    progressive_2: 'The progressive airdrop consists of four phases, with ongoing airdrops awarded based on the completion of Key Performance Indicators (KPIs) at each stage.',
    step_1_title: 'Airdrop',
    step_1_content: 'Criteria for Selecting High-Quality Projects',
    step_2_title: 'Community Expansion',
    step_2_content: 'Dissemination of community promotion Impact of the project',
    step_3_title: 'Apostle Behavior',
    step_3_content: 'Willingness to convince others to use the project\'s products',
    step_4_title: 'Loyalty Alliance',
    step_4_content: 'Long-term companionship, continuously receiving rewards from the project Continuously providing the project with personalized experiences, interactions, and user engagement',

    futureTitle: 'The future development of AISI DAO',
    future_1: 'With the advent of the Web3 era, Aisi Qunyou has also received the attention and recognition of many international social industry elites. The Aisi community also needs to expand to the global development level, using the DAO organizational structure to continue the development of the industry. Make due contributions. Based on the AISI WeChat group, it was upgraded to the AISI DAO community, covering WeChat, Telegram, and Twitter. It spread from the domestic to the global community of blockchain enthusiasts. The community spread greatly and became a community of tens of thousands of people, opening up a new generation of progressive rights and interests. Airdrop model social experiment. If APE is a gathering place for elites from all walks of life around the world, and YGG is the largest trade union in the world, then the goal of AISI DAO is to develop from the first Chinese blockchain community to the largest Chinese community.',

    backgroundTitle: 'Track background',
    background_1: 'The fair launch of BRC20 is fully in line with the thoughts of the AISI community. This year, countlesswealth myths were born in the BRC circuit. The cost of ORDI casting was 2U per piece, which rose to  maximum of 69,000U per piece, an increase of 34,500 times during the year. BRC20 has set off a trillion-bit Bitcoin ecosystem. Top exchanges such as Binance and OKX have participated in the construction of the BRC ecosystem. BRC will surely become the biggest new narrative of this bull market. The founder of Aisi Community began to preach on the BRC track in May this year. At the invitation of HuoBi and Gate exchange, he made several live broadcasts on Twitter and conducted in-depth research on the BRC track.',

    inscriptionTitle: 'AISI inscription deployment and mint completed',
    inscription_1: 'On June 8, 2023, aisi inscription was deployed on BRC20 by an unknown person. An inscription was minted with a progress of 0.1%.On November 16, 2023, the aisi inscription was discovered by Mr. Ameng, a member of the Aisi community and forwarded to the aisi community. At that time, the inscription progress was 0.1%. On December 21, 2023, after three hours, all the AISI inscriptions were completed. Here During this period, Inscription received widespread attention and participation from the community and retail investors. The initial market value of Inscription was approximately 1 million, with the top 10 holdings accounting for 9.93%. There were approximately 1,000 initial addresses. From 0.1%, the community discovered that the casting process was completed, and the chips were dispersed, fully in line with the Inscription competition. The original meaning and spirit of Tao have been recognized by the Aisi community, and the Aisi inscription has become the new generation of Aisi tokens.',
    inscription_2: 'The AISI community is open and transparent, embracing all communities and projects, inheriting the tradition of aisi token airdrop, and making the AISI inscription a golden shovel for airdrop. The first airdrop 20EX has been airdropped on AISI, and more airdrops are in progress. ',
    amount_1: 'Total amount：42 000 000',
    amount_2: 'Number of sheets：42 000',
    amount_3: 'Each sheet contains：1000',

    footer_link_1: 'AISI Community Twitter',
    footer_link_2: 'AISI Twitter (English)',
    footer_link_3: 'AISI Telegram (Chinese)',
    footer_link_4: 'AISI Telegram (English)',

    footer_link_5: 'AISI K-line chart',
    footer_link_6: 'How to Buy AISI',
    footer_link_7: 'Buy AISI through OKX',
    footer_link_8: 'Buy AISI through Unisat',

}
export default en;  