import { Flex } from 'antd';
import { useIntl } from 'react-intl';
import Item from './Item';

const Progressive = () => {
    const intl = useIntl();
    return (
        <>
            <div className='page_container'>
                <Flex vertical className='mobile page_content'>
                    {/* <div className="revolution_paint"></div> */}
                    <img src='/img/bar-1.png' alt='' style={{ margin: 0, position: 'relative', top: 1 }} />
                    <Flex vertical gap={5} style={{ backgroundColor: '#ff9a00', width: '100%' }}>
                        <p className='page_title' style={{ color: 'black', margin: 10 }}>{intl.formatMessage({ id: 'progressiveTitle' })}</p>
                        <Flex wrap='wrap' justify='center' style={{ position: 'relative', gap: '1vw' }}>
                            {/* <Step imgSrc={'/img/kit-1.png'} title={intl.formatMessage({ id: 'step_1_title' })}
                                text={intl.formatMessage({ id: 'step_1_content' })} />
                            <Step /> */}
                            <Item imgSrc={'/img/kit-1.png'} title={intl.formatMessage({ id: 'step_1_title' })}
                                text={intl.formatMessage({ id: 'step_1_content' })} />
                            <Item imgSrc={'/img/kit-2.png'} title={intl.formatMessage({ id: 'step_2_title' })}
                                text={intl.formatMessage({ id: 'step_2_content' })} />
                            <Item imgSrc={'/img/kit-3.png'} title={intl.formatMessage({ id: 'step_3_title' })}
                                text={intl.formatMessage({ id: 'step_3_content' })} />
                            <Item imgSrc={'/img/kit-4.png'} title={intl.formatMessage({ id: 'step_4_title' })}
                                text={intl.formatMessage({ id: 'step_4_content' })} />
                        </Flex>
                        <p className='page_info' style={{ color: 'black', marginLeft: 10, marginRight: 10 }}>{intl.formatMessage({ id: 'progressive_1' })}</p>
                        <p className='page_info' style={{ color: 'black', marginLeft: 10, marginRight: 10 }}>{intl.formatMessage({ id: 'progressive_2' })}</p>
                    </Flex>
                    <img src='/img/bar-2.png' alt='' style={{ position: 'relative', bottom: 2 }} />
                </Flex>


                <Flex vertical gap={0} className='pc page_content' style={{ gap: 0, margin: 0 }}>
                    <img src='/img/bar-1.png' alt='' style={{ margin: 0, position: 'relative', top: '1vw', width: '100%' }} />
                    <Flex vertical gap={5} align='center' style={{ backgroundColor: '#ff9a00', width: '100%', paddingTop: '2vw' }}>
                        <p className='page_title' style={{ color: 'black', marginLeft: 10, marginRight: 10 }}>{intl.formatMessage({ id: 'progressiveTitle' })}</p>
                        <p className='page_info' style={{ color: 'black', marginLeft: 10, marginRight: 10 }} >{intl.formatMessage({ id: 'progressive_1' })}</p>
                        <p className='page_info' style={{ color: 'black', marginLeft: 10, marginRight: 10 }}>{intl.formatMessage({ id: 'progressive_2' })}</p>
                        <Flex wrap='wrap' justify='center' style={{ position: 'relative' }}>
                            {/* <Step imgSrc={'/img/kit-1.png'} title={intl.formatMessage({ id: 'step_1_title' })}
                                text={intl.formatMessage({ id: 'step_1_content' })} />
                            <Step /> */}
                            <Item imgSrc={'/img/kit-1.png'} title={intl.formatMessage({ id: 'step_1_title' })}
                                text={intl.formatMessage({ id: 'step_1_content' })} />
                            <Item imgSrc={'/img/kit-2.png'} title={intl.formatMessage({ id: 'step_2_title' })}
                                text={intl.formatMessage({ id: 'step_2_content' })} />
                            <Item imgSrc={'/img/kit-3.png'} title={intl.formatMessage({ id: 'step_3_title' })}
                                text={intl.formatMessage({ id: 'step_3_content' })} />
                            <Item imgSrc={'/img/kit-4.png'} title={intl.formatMessage({ id: 'step_4_title' })}
                                text={intl.formatMessage({ id: 'step_4_content' })} />
                            <img src='/img/rotatecoin.png' alt='' style={{ position: 'absolute', width: '16vw' }} />
                        </Flex>
                    </Flex>
                    <img src='/img/bar-2.png' alt='' style={{ position: 'relative', bottom: 2, width: '100%' }} />
                </Flex>
            </div>
        </>
    )
}
export default Progressive;