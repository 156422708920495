import { Dropdown, Space, } from 'antd';
import { useIntl } from 'react-intl';
import { LangMobile, LangPC } from './util';
const LangSelect = (props) => {
    const { setLang } = props;
    const intl = useIntl();
    const setLanguage = (lang) => {
        setLang(lang);
    }
    const items = [
        {
            key: '1',
            label: (
                <span className='font_neue'>English</span>
            ),
            onClick: () => { setLanguage('en') },

        },
        {
            key: '2',
            label: (
                <span className='font_neue'>中文</span>
            ),
            onClick: () => { setLanguage('zh') },
        },

    ];
    return (
        <div >
            <Dropdown menu={{ items }}>
                <Space style={{ margin: '10px', cursor: 'pointer' }}>
                    {/* <TranslationOutlined style={{ fontSize: '20px', Fcolor: 'black' }} /> */}
                    {window.innerWidth >= 800 ? <LangPC /> : <LangMobile />}
                    <span style={{ color: 'white', fontSize: window.innerWidth >= 800 ? 'large' : 'small' }} className='font_neue'>{intl.formatMessage({ id: 'switchLang' })}</span>
                </Space>
            </Dropdown>
        </div>
    )
}

export default LangSelect;