import { Flex, Row } from "antd";
import LangSelect from "../LangSelect";
import './Nav.css';

const Nav = (props) => {
    const { setLang } = props;
    // console.log(setLang);
    return (
        <div className="page_container">
            <Row justify={'space-between'} className="nav" align={'middle'}>
                <Flex align={'center'} gap={5}>
                    <img src="/img/aisilogo.png" className="nav_logo" alt="logo" />
                    <span className="font_inter nav_title">AISI DAO</span>
                </Flex>
                <LangSelect setLang={setLang} />
            </Row >
        </div>
    )
}
export default Nav;