
const LogoLT = (props) => {
    const { width } = props;
    const totalWidth = width * 2;
    return (
        <div style={{ position: 'absolute', top: 5, left: 5, height: totalWidth, width: totalWidth }}>
            <img src="/img/aisilogo.png" width={totalWidth} alt=""></img>
        </div>
    )
}
export default LogoLT;